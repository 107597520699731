@tailwind base;
@layer base {
  body {
    /* @apply bg-[#f8f7f7]; */
    @apply bg-roophGray;
  }
  html {
    @apply text-gray-800;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-gray-900;
    @apply font-heading;
    @apply font-bold;
  }

  .builder-container h3 {
    font-size: 20px;
  }

  a,
  button,
  input,
  select {
    @apply transition;
  }

  a:focus {
    @apply shadow-outline;
    @apply outline-0;
    outline: none;
  }

  select.form-control {
    @apply pr-5;
    @apply whitespace-nowrap;
    @apply text-ellipsis;
  }

  .form-control {
    @apply h-10;
    @apply w-full;
    @apply border;
    @apply border-gray-100;
    @apply rounded-lg;
    @apply pl-3;
    @apply pr-3;
    @apply bg-white;
  }

  select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="100%" height="100%" viewBox="0 0 24 24" stroke="currentcolor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" /></svg>')
      no-repeat;
    background-size: 12px;
    background-position: calc(100% - 1px) calc(50% + 1px);
    background-repeat: no-repeat;
  }

  .outline-none:focus-visible {
    outline: none;
  }

  .form-control:focus,
  .form-control:focus-visible {
    @apply outline-0;
    @apply shadow-form-outline;
    @apply border-tertiary;
    outline: none;
  }

  button:focus,
  button:focus-visible {
    @apply shadow-outline;
    @apply outline-0;
    outline: none;
  }

  button:disabled {
    opacity: 0.4;
  }

  .primary {
    @apply bg-gradient-to-b;
    @apply from-primary;
    @apply to-secondary;
    @apply px-4;
    @apply h-10;
    @apply text-white;
    @apply rounded-[18px];
    @apply z-[1];
    @apply before:content-[''];
    @apply relative;
  }

  .primary::before {
    @apply absolute;
    @apply top-0;
    @apply left-0;
    @apply w-full;
    @apply h-full;
    @apply bg-gradient-to-b;
    @apply from-dark-primary;
    @apply to-dark-primary;
    @apply opacity-0;
    @apply transition-opacity;
    @apply z-[-1];
    @apply rounded-[18px];
  }

  .primary:not(:disabled):hover::before {
    @apply opacity-100;
  }

  .secondary {
    @apply bg-white;
    @apply text-primary;
    @apply border;
    @apply border-primary;
    @apply px-4;
    @apply rounded-[18px];
    @apply h-10;
  }

  .secondary:hover {
    @apply bg-quaternary;
  }

  .table-container {
    @apply relative;
    @apply overflow-x-auto;
    @apply shadow-md;
    @apply sm:rounded-lg;
  }

  table {
    @apply w-full;
    @apply text-sm;
    @apply text-left;
  }

  thead {
    @apply text-xs;
    @apply text-gray-700;
    @apply uppercase;
    @apply bg-gray-50;
  }

  th {
    @apply px-6;
    @apply py-3;
  }

  tbody tr {
    @apply border-b;
  }

  td {
    @apply px-6;
    @apply py-4;
  }

  .bg-primary-gradient {
    @apply bg-gradient-to-b;
    @apply from-primary;
    @apply to-secondary;
  }
}
@tailwind components;
@tailwind utilities;


@layer components {
  .btn {
    @apply px-5 py-3 font-semibold rounded-full text-center inline-block transition duration-300 hover:shadow-md;
  }

  .btn-rooph-primary {
    @apply bg-roophGreen3 text-roophGreen1;
  }

  .btn-rooph-secondary {
    @apply bg-white border-2 border-roophGreen3;
  }

  .btn-rooph-medium {
    @apply bg-roophGreen2 text-white;
  }

  .rooph-h1 {
    @apply text-roophGreen1 font-figtree sm:font-extrabold font-bold leading-[42px] tracking-normal text-[36px] sm:text-[48px] sm:leading-[58px];
  }

  .rooph-h2 {
    @apply text-roophGreen1 font-figtree sm:font-extrabold font-bold leading-[32px] tracking-normal text-[24px] sm:text-[28px] sm:leading-[38px];
  }

  .error {
    @apply text-roophRed font-figtree font-semibold leading-8
  }
}

